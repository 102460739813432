import {
    VictoryBar, 
    Bar, 
    VictoryTooltip, 
    VictoryLabel, 
    VictoryChart, 
    VictoryCursorContainer, 
    VictoryZoomContainer
} from "victory";
import styles from "./ChartArea.module.scss"
import {NoData} from "../NoData/NoData";

export function ChartArea(props) {
    let quantity = [];

    props.chart_data.map((item) => {
        quantity.push(Number.parseInt(item.value))
    });

    const isLiters = props.chart_data[0]?.value?.includes('л') ?? false

    let maxDom = Math.max(...quantity) + 2;

    const data = props.chart_data.map(item => ({
        ...item,
        value: Number.parseInt(item.value)
    }))

    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        maximumFractionDigits: 0,
    });

    const handleBarClick = (event) => {
        if (props.onClick) {
            props.onClick(event)
        }
    }
    // onClick={handleBarClick}

    return (    
        props.chart_data.length > 0
            ? <div  className="scrollbox scrollbox--rounded">
                <VictoryChart
                    vertical 
                    height={400}
                    width={1150}
                    className={styles.VictoryContainerBox}
                    maxDomain={{y: maxDom, x: data.length + 1}}
                    animate={{
                        duration: 500,
                        onLoad: { duration: 200 }
                    }}
                    // domain={{ x: 'auto' }}
                    domainPadding={{ x: 1, y: 1 }}
                    containerComponent={
                        <VictoryZoomContainer responsive={false}
                                            zoomDimension="x"
                        />
                    }
                >
                    <VictoryBar
                        style={
                            {
                                data: {
                                    stroke: "#D02239",
                                    strokeWidth: 2,
                                    fill: "rgba(208, 34, 57, 0.15)",
                                },
                                labels: { padding: 0 }
                            }
                        }
                        alignment="middle"
                        x={"period"}
                        y={"value"}
                        padding={{ right: 100 }}
                        // barRatio={0.8}
                        labels={({ datum }) => `${!isLiters ? formatter.format(datum.value) : `${datum.value} л`}`}
                        labelComponent={
                            <VictoryTooltip
                                active
                                labelPlacement="perpendicular"
                                stroke="transparent"
                                labelComponent={
                                    <VictoryLabel
                                        verticalAnchor="end"
                                        dy={0}
                                        backgroundPadding={8}
                                    />
                                }
                            />
                        }
                        dataComponent={
                            <Bar
                                events={{
                                    onClick: handleBarClick
                                }}
                            />
                        }
                        interpolation={"natural"}
                        data={data}
                        containerComponent={
                            <VictoryCursorContainer
                                cursorDimension="y"
                                onCursorChange={(value, props) => console.log(value, props)}
                                cursorLabel={({ datum }) => `${datum.value}}`}
                                style={{
                                    data: {
                                        userSelect: "auto",
                                        pointerEvents: "auto",
                                        touchAction: "auto",
                                    }
                                }}
                            />
                        }
                        scale={{x: "time"}}
                    />

                </VictoryChart>
            </div>

            : <NoData/>
    )
}
