import { Link, useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import clsx from "clsx";
import Select from 'react-select';
import { distrStatMachine } from "@/js/xstate/distributor.machine";
import { Loader } from "../Loader/Loader";
import { ChartArea } from "../ChartArea/ChartArea";
import styles from "./DistributorStat.module.scss";
import { ChartSelect } from "../ChartSelect/ChartSelect";
import { periodsList, typeList } from "@/js/constants";
import { NoData } from "../NoData/NoData";
import { Pagination } from "../Pagination/Pagination";

export function DistributorStat() {
  const { distributorID = "" } = useParams();

  const [stateDistr, sendDistr] = useMachine(distrStatMachine, {
    context: {
      ...distrStatMachine.context,
      id: distributorID.replace(/distr/g, ""),
    },
  });

  const goodsOptions = () => {
    if (stateDistr.context.goods && stateDistr.context.goods.length) {
    return stateDistr.context.goods.map((item) => ({
            value: item.name,
            label: item.name,
        }));
    }

    return [];
  };

  const pointsOptions = () => {
    if (stateDistr.context.pointsList && stateDistr.context.pointsList.length) {
      return stateDistr.context.pointsList.map((item) => ({
          value: item.id,
          label: item.name,
        }));
    }

    return [];
  };

  const ownersOptions = () => {
    if (stateDistr.context.ownersList && stateDistr.context.ownersList.length) {
      return stateDistr.context.ownersList.map((item) => ({
          value: item.id,
          label: `${item.name} ${item.surname}`,
        }));
    }

    return [];
  };

  const handleCLick = (event) => {
    if (stateDistr.context.period.value === 'week') return
    const keys = {
      six_month: {
        label: "Месяц",
        value: "month"
      },
      month: {
        label: "Неделя (по дням)",
        value: "week"
      },
    }

    const barIndex = event.target.getAttribute('index');

    sendDistr({
      type: "CHANGE_FILTER",
      data: {
          period: keys[stateDistr.context.period.value],
          ...(barIndex >= 0 ? { date: stateDistr.context.chart_data[barIndex].date } : {})
      }
    })
  }

  const handleChange = (val, options) => {
    sendDistr({
      type: options.type,
      data: {
          [options.name]: val
      }
    })
  }

  return (
    <>
      <h1>Статистика торговых точек</h1>

      {stateDistr.context.errorMsg.length > 0 &&
        stateDistr.context.errorMsg.map((item, index) => (
          <div className="errorMessage" key={index}>
            {item}
          </div>
        ))}

      <section className={clsx("statContainer", styles.chartArea)}>
        <div className={styles.titleBlock}>
          <h2>График продаж, сумма продаж</h2>

          <div className={styles.chartControls}>
            <Select
              name="type"
              placeholder="Выбор типа"
              styles={{
                  control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      minHeight: 24,
                      // height: 24,
                      width: 250
              }), input: (baseStyle, state) => ({
                  ...baseStyle,
                  // height: 24
              }), indicatorSeparator: (baseStyle, state) => ({
                  display: 'none'
              })}}
              defaultValue={typeList[0]}
              value={stateDistr.context.type}
              options={[...typeList]}
              onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "type"})}
              className="basic-multi-select"
              classNamePrefix="select"
            />

            <Select
              name="period"
              placeholder="Выбор периода"
              styles={{
                  control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      minHeight: 24,
                      // height: 24,
                      width: 250
              }), input: (baseStyle, state) => ({
                  ...baseStyle,
                  // height: 24
              }), indicatorSeparator: (baseStyle, state) => ({
                  display: 'none'
              })}}
              value={stateDistr.context.period}
              options={[...periodsList]}
              onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "period"})}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            {/* <ChartSelect
                send={sendDistr}
                value={stateDistr.context.period}
                type="CHANGE_FILTER"
                dataName="period"
                label="Выбор периода"
                name="periodSelect"
                defaultValue="week"
                options={[...periodsList]}
            /> */}
            <Select
              name="good"
              placeholder="Все товары ZIC"
              styles={{
                  control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      minHeight: 24,
                      // height: 24,
                      width: 250
              }), input: (baseStyle, state) => ({
                  ...baseStyle,
                  // height: 24
              }), indicatorSeparator: (baseStyle, state) => ({
                  display: 'none'
              })}}
              value={stateDistr.context.good}
              options={[{
                      value: '',
                      label: "Выбор товара",
                  }, ...goodsOptions()]}
              onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "good"})}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            {/* <ChartSelect
              send={sendDistr}
              type="CHANGE_FILTER"
              dataName="good"
              label="Выбор товара"
              name="goodSelect"
              defaultValue="''"
              options={[
                  {
                      id: '',
                      name: "Все товары ZIC",
                  },
                  ...goodsOptions(),
              ]}
            /> */}
            <Select
              isMulti
              name="points"
              placeholder="Все торговые точки ZIC"
              styles={{
                  control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      minHeight: 24,
                      // height: 24,
                      width: 250
              }), input: (baseStyle, state) => ({
                  ...baseStyle,
                  // height: 24
              }), indicatorSeparator: (baseStyle, state) => ({
                  display: 'none'
              })}}
              value={stateDistr.context.points}
              options={[...pointsOptions()]}
              onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "points"})}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <Select
              isMulti
              name="owners"
              placeholder="Все Партнеры ZIC"
              styles={{
                  control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: 'none',
                      minHeight: 24,
                      // height: 24,
                      width: 250
              }), input: (baseStyle, state) => ({
                  ...baseStyle,
                  // height: 24
              }), indicatorSeparator: (baseStyle, state) => ({
                  display: 'none'
              })}}
              value={stateDistr.context.owners}
              options={[...ownersOptions()]}
              onChange={(value) => handleChange(value, {type: "CHANGE_FILTER", name: "owners"})}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
        {stateDistr.matches({ chart: "loading" }) ? (
          <Loader />
        ) : (
          <ChartArea onClick={handleCLick} chart_data={stateDistr.context.chart_data} />
        )}
      </section>

      <section className="statContainer">
        <h2 className="visuallyHidden">Таблица продаж</h2>

        {stateDistr.context.list.length > 0 ? (
          <>
            <ShopsTable data={stateDistr.context.list} />
            {stateDistr.context.pages > 1 && (
              <Pagination
                pages={stateDistr.context.pages}
                current_page={stateDistr.context.current_page}
                send={sendDistr}
              />
            )}
          </>
        ) : (
          <NoData />
        )}
      </section>
    </>
  );
}

function ShopsTable(props) {
  if (props.data.length === 0) {
    return <NoData />;
  }
  return (
    <div className="scrollbox">
      <table id="distrTable" className="statTable">
        <thead>
          <tr>
            <th>Торговая точка</th>
            <th>Адрес ТТ</th>
            <th>Кол-во продавцов</th>
            <th>Продано ед.</th>
            <th>Продажи руб.</th>
            <th>Литры</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item) => (
            <tr key={item.id}>
              <td className={styles.itemName}>
                {/* <Link to={`shop${item.id}`}> */}
                  {item.point}
                  {/* </Link> */}
              </td>
              <td className="noWhiteSpace">{item.address}</td>
              <td>{item.sellers}</td>
              <td>{new Intl.NumberFormat("ru-RU").format(item.quantity)} ед.</td>
              <td className="noWhiteSpace">
                {new Intl.NumberFormat("ru-RU").format(item.sum)} &#8381;
              </td>
              <td className="noWhiteSpace">
                {new Intl.NumberFormat("ru-RU").format(item.liters)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
