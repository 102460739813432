import { useCallback, useEffect, useState } from "react";
import { FormTemplate } from "../../FormTemplate/FormTemplate";
import apiMethods from "../../../../api/apiMethods";
import { patterns, validationErrorMessages } from "../../../../constants";
import { useForm } from "react-hook-form";
import { SuccessPopup } from "../../../SuccessPopup/SuccessPopup";

const getSalePointsOptions = (data) => {
    return data.map(item => ({name: item.name, value: item.id}));
}

const parsePhone = (value) => {
    if (isNaN(value) || value === ' ' || value === '') {
        return ""
    }
    return parseInt(value);
}

const PHONE_LENGTH = 10;
const title = 'Добавление Продавца';

export function AddSellerForm() {
  const [ loading, setLoading ] = useState(false);
  const [errorsList, setErrorsList] = useState([])
  const [salePointOptions, setSailPointOptions] = useState([]);
  const { register, handleSubmit, setValue, setError, formState: { errors } } = useForm();
  const [successMessage, setSuccessMessage] = useState(null);
  const fetchSalingPoints = useCallback( async () => {
      setLoading(true);
      try {
        //   setErrors([])
          const {data} = await apiMethods.owner.getSalePoints();
          setSailPointOptions(getSalePointsOptions(data.data))
          setLoading(false);
      } catch ({response}) {
          setLoading(false);
      }    
  }, []);

  useEffect(() => {
      document.title = `${title} | Программа лояльности SK ZIC`;
  }, []);

  useEffect(() => {
      fetchSalingPoints();
  }, [fetchSalingPoints]);

  const fetchFormData = useCallback(async(body) => {
      try {
          const {data} = await apiMethods.owner.postSeller(body);
          setSuccessMessage(data.message);
      } catch ({response}) {
        if (response.data.message) {
            setErrorsList([response.data.message])
        } else if (Array.isArray(response.data.message)) {
            setErrorsList(response.data.message)
        }

          if (response.status === 422) {
              Object.entries(response.data.errors).forEach(([key, val]) => {
                  setError(key, { type: 'custom', message: val[0]})
          } )
          }
      }
  })

  const submit = data => {
      fetchFormData(data);
  }



  return (
    <FormTemplate title={title} isLoading={loading}>
      {
        <>
          <form                             
            onSubmit={handleSubmit(submit)}
            noValidate
          > 
            {errorsList.map((item, index) => (
                <div className="errorMessage" key={index}>
                    {item}
                </div>
            ))}

            <div className="formRow">
                <label htmlFor="name">
                    Имя
                </label>
                <input
                    id="name"
                    type="text"
                    name="name"
                    {...register("name", { required: validationErrorMessages.REQUIRED })}

                />
                {
                    Boolean(errors.name?.message) && 
                        <div className={"errorMessage"}>
                            {errors.name.message }
                        </div>
                }

            </div>
            <div className="formRow">
                <label htmlFor="surname">
                    Фамилия
                </label>
                <input
                    id="surname"
                    type="text"
                    name="surname"
                    {...register("surname", { required: validationErrorMessages.REQUIRED })}

                />
                {
                    Boolean(errors.surname?.message) && 
                        <div className={"errorMessage"}>
                            {errors.surname.message }
                        </div>
                }
            </div>
            <div className="formRow">
                <label htmlFor="email">
                    E-mail
                </label>
                <input
                    id="email"
                    type="email"
                    name="email"
                    {...register("email", 
                        { 
                            required: validationErrorMessages.REQUIRED, 
                            pattern: {value: patterns.EMAIL, message: validationErrorMessages.INVALID_INPUT
                    } })}

                />
                {
                    Boolean(errors.email?.message) && 
                        <div className={"errorMessage"}>
                            {errors.email.message }
                        </div>
                }
            </div>
            <div className="formRow">
                <label htmlFor="phone">
                    Телефон
                </label>
                <div className="inputWrapper telWrapper">
                    <input
                        className={"phone"}
                        id="phone"
                        type="tel"
                        pattern={"[0-9]{10}"}
                        minLength={10}
                        maxLength={10}
                        name="phone"
                        {...register("phone", 
                            { 
                                required: validationErrorMessages.REQUIRED, 
                                pattern: {value: patterns.PHONE, message: validationErrorMessages.INVALID_INPUT},
                                minLength: {value: 10, message: `${validationErrorMessages.MIN_LENGTH}${PHONE_LENGTH}`},
                                maxLength:{value: 10, message: `${validationErrorMessages.MAX_LENGTH}${PHONE_LENGTH}`},
                                onChange: (event) => setValue("phone", parsePhone(event.target.value)),
                            }, 
                        
                        )}

                    />
                </div>
                <span className={"note"}>Формат: 10 цифр</span>
                {
                    Boolean(errors.phone?.message) && 
                        <div className={"errorMessage"}>
                            {errors.phone.message }
                        </div>
                }

            </div>
            <div className="formRow">
                <label htmlFor="sale_point">
                    Торговая точка
                </label>
                <select
                    className="select"
                    id="sale_point"
                    type="text"
                    name="sale_point"
                    {...register("sale_point", { required: validationErrorMessages.REQUIRED })}
                >
                {
                    Boolean(errors.sale_point?.message) && 
                        <div className={"errorMessage"}>
                            {errors.sale_point.message }
                        </div>
                }
                    {
                        salePointOptions?.length > 0 &&
                        salePointOptions.map(option => 
                        <option key={option.value} value={option.value}>{option.name}</option>)
                    }
                </select>

            </div>
            <div className="formRow">
                <button type={"submit"} className={"btn btnPrimary"}>
                    Зарегистрировать
                </button>
            </div>
          </form>
          <SuccessPopup
            isOpen={Boolean(successMessage)}
            onClose={() => setSuccessMessage('')} 
            title={'Продавец успешно добавлен'}
            text={successMessage}
          />
        </>  
      }
    </FormTemplate>
  )
}