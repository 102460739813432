import {useEffect} from "react";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {useActor, useMachine} from "@xstate/react";
import {Icon} from "../SVGsprite/svgsprite";
import styles from "./MyReceipts.module.scss";
import {Loader} from "../Loader/Loader";
import {myReceiptsMachine} from "../../xstate/myreceipts.machine";
import {Pagination} from "../Pagination/Pagination";
import {Banners} from "../Banners/Banners";
import {Bonuses} from "../Bonuses/Bonuses";
import {NoData} from "../NoData/NoData";
import {AuthState} from "../../xstate/auth.machine";

export function MyReceipts() {
    const [stateAuth] = useActor(AuthState);
    const [stateReceipts, sendReceipts] = useMachine(myReceiptsMachine);

    useEffect(() => {
        document.title = "Мои чеки | Программа лояльности SK ZIC";
    }, []);

    return (
        <>
            <section className={styles.workarea}>
                <div className="titleBlock">
                    <h1>Мои чеки</h1>
                    {
                        stateAuth.context.can_upload && !['owner', 'distributor'].includes(stateAuth.context.type) && 
                        <Link to="/account/upload" className="btn btnPrimary btnIcon">
                            <Icon name="icon-add" sizeW="24" sizeH="24"/>
                            Загрузить чек
                        </Link>
                    }
                </div>

                <div className="statContainer">
                    {
                        stateReceipts.matches("loading")
                            ? <Loader/>
                            : <>
                                <ReceiptsTable data={stateReceipts.context.list}/>
                                {
                                    (stateReceipts.context.meta.lastPage > 1 || stateReceipts.context.meta.pages > 1) &&
                                    <Pagination
                                        pages={stateReceipts.context.pages}
                                        current_page={stateReceipts.context.current_page}
                                        send={sendReceipts}
                                    />
                                }
                            </>
                    }
                </div>
            </section>
            <aside className={styles.sidebar}>
                {/* <Bonuses/> */}
                <Banners/>
            </aside>
        </>)
}

function ReceiptsTable(props) {

    if (props.data.length === 0) {
        return <NoData/>
    }
    return (
        <div className="scrollbox">
            <table className="statTable">
                <thead>
                <tr>
                    <th>Дата загрузки чека</th>
                    <th>Дата и время продажи</th>
                    <th>Фото чека</th>
                    <th>ФД чека</th>
                    <th className={styles.status}>Статус</th>
                    <th>Баллы</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map(
                        (item) => <tr key={item.id}>
                                <td>{new Date(item.created_at).toLocaleString()}</td>
                                <td>{new Date(item.sale_at).toLocaleString()}</td>
                                <td>
                                    {
                                        (item.photos?.length) &&
                                        <img src={item.photos[0].path} />
                                    }
                                </td>
                                <td>{item.fd}</td>
                                <td className={styles.status}>
                                    <span className={
                                        clsx(
                                            styles.stateValue,
                                            {
                                                [styles.stateDeclined]: item.status.slug === "reject",
                                                [styles.stateProcessing]: item.status.slug === "in_process",
                                                [styles.stateAccepted]: item.status.slug === 'approve',
                                            }
                                        )
                                    }
                                    >
                                        {item.status.i18n}
                                    </span>
                                    {/* {
                                        item.status.message.length > 0 &&
                                        <span className={styles.message}>
                                            {item.status.message}
                                        </span>
                                    } */}
                                </td>
                                <td>
                                    {item.points}
                                    {/* <span
                                        className={clsx(styles.flow, {
                                            [styles.stateDeclined]: item.status.state === "Отклонен",
                                            [styles.stateProcessing]: item.status.state === "В обработке",
                                            [styles.stateAccepted]: item.status.state === "Принят",
                                        })}
                                    >
                                        {item.flow}
                                    </span> */}
                                </td>
                            </tr>
                    )
                }
                </tbody>
            </table>
        </div>

    )
}