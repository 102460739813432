import { Pagination } from "@/js/modules/Pagination/Pagination";
import { Table } from '@/js/modules/Table/Table';
import React, { useCallback } from 'react';
// import { useNavigate } from 'react-router-dom'

const statuses = {
    in_process: {
        label: 'В обработке',
        color: 'orange'
    }, 
    active: {
        label: 'Активная',
        color: 'lightgreen'
    },
    inactive: {
        label: 'Неактивная',
        color: 'grey'
    }, 
    reject: {
        label: 'Отклоненная',
        color: 'red'
    }
}

export const TableSalePointsAll = (props) => {
  const {
    columns,
    statePoints,
    meta,
    onSend,
  } = props

  // const navigate = useNavigate()

  // const handleClick = useCallback((id) => {
  //   navigate(`/account/sale-point-edit/${id}`)
  // }, [])


  const tableDataNew = statePoints.context.list.map(item => ({
    name: item.name,
    owner: item.owner,
    entity_name: item.entity_name,
    region: item.region,
    address: item.address,
    status: <span style={{color: statuses[item.status.slug]?.color ?? 'black'}}>{item.status.i18n}</span>,
    // edit: <button onClick={() => handleClick(item.id)}>Редактировать</button>
  }))


  return (
    <div>
      <Table 
        headers={columns}
        overflow="auto"
        tableData={tableDataNew}
        isLoading={statePoints.matches("loading")}
      />
      {
        statePoints.context.list.length && meta.lastPage > 1 && !statePoints.matches("loading") ?
        <Pagination 
          pages={statePoints.context.pages}
          current_page={statePoints.context.current_page}
          send={onSend}
        /> : <></>
      }
    </div>
  )
}